import React, { Component } from "react";

import i18n from "i18next";

export const AppContext = React.createContext();
export const SimulatorConsumer = AppContext.Consumer;

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
var productEntry = 0;

class SimulatorProvider extends Component {
    state = {
        viewLocation: "outdoor",
        viewLocationPrevious: "outdoor",
        backgroundAudio: ["Hintergrund-Sound_TAG.mp3", "Hintergrund-Sound_TAG.mp3", "Hintergrund-Sound_NACHT.mp3", "Hintergrund-Sound_NACHT.mp3"],
        selectedBackgroundAudio: "",
        initialLoad: true,
        productSelected: productEntry,
        entryProduct: 0,
        activeView: 0,
        time: false,
        heatpumpAudio: false,
        disableSlider: true,
        heatpumpVolume: 0.4,
        refernceHeatpumpVolume: 0.4,
        appVolume: 0.3,
        backgroundVolume: 1,
        menuOpen: true,
        menuIsFloating: false,
        ambientAudio: true,
        audioContext: {},
        gainNode: {},
        mediaSource: {},
        products: i18n.t("themes", { returnObjects: true }),
        btnThemes: i18n.t("buttons", { returnObjects: true }),
        fonts: i18n.t("fonts", { returnObjects: true }),
        userTracked: false,
        acceptedTerms: false,
        backgroundAudioLevels: {
            outdoorDay: 1,
            outdoorNight: 0.5,
            indoorDay: 0.1,
            indoorNight: 0.05,
        },
        heatpumpAudioLevels: {
            outdoor3mDay: 0.5,
            outdoor1mDay: 1,
            outdoor3mNight: 0.5,
            outdoor1mNight: 1,
            indoorDay: 0.1,
            indoorNight: 0.1,
        },
    };

    setProduct = (product) => {
        this.setState((prevState) => ({ productSelected: product }));
    };

    setEntryProduct = (product) => {
        this.setState((prevState) => ({ entryProduct: product }));
    };

    setProducts = (productsLng) => {
        this.setState({ products: productsLng });
    };

    setHeatpumpAudio = (audioState) => {
        this.setState((prevState) => ({ heatpumpAudio: audioState }));
    };

    setHeatpumpVolume = (volume) => {
        this.setState((prevState) => ({ heatpumpVolume: volume }));
    };

    setViewLocation = (viewLocation) => {
        this.setState((prevState) => ({ viewLocation: viewLocation }));
    };

    setViewLocationPrevious = (viewLocation) => {
        this.setState((prevState) => ({ viewLocationPrevious: viewLocation }));
    };

    setActiveView = (view) => {
        this.setState((prevState) => ({ activeView: view }));
    };

    setTime = (time) => {
        this.setState((prevState) => ({ time: time }));
    };

    setAppVolume = (volume) => {
        this.setState((prevState) => ({ appVolume: volume }));
    };

    setBackgroundVolume = (volume) => {
        this.setState((prevState) => ({ backgroundVolume: volume }));
    };

    setMenu = (state) => {
        this.setState((prevState) => ({ menuOpen: state }));
    };

    setMenuFloating = (state) => {
        this.setState((prevState) => ({ menuIsFloating: state }));
    };

    setAmbient = (state) => {
        this.setState((prevState) => ({ ambientAudio: state }));
    };

    setInitialLoad = (state) => {
        this.setState((prevState) => ({ initialLoad: state }));
    };

    setSelectedBackgroundAudio = (state) => {
        this.setState((prevState) => ({ selectedBackgroundAudio: state }));
    };

    setAudioContext = (state) => {
        this.setState((prevState) => ({ audioContext: state }));
    };

    setGainNode = (state) => {
        this.setState((prevState) => ({ gainNode: state }));
    };

    setMediaSource = (state) => {
        this.setState((prevState) => ({ mediaSource: state }));
    };

    trackUser = (state) => {
        this.setState((prevState) => ({ userTracked: state }));
    };

    setTerms = (state) => {
        this.setState((prevState) => ({ acceptedTerms: state }));
    };

    setSliderState = (state) => {
        this.setState((prevState) => ({ disableSlider: state }));
    };

    render() {
        const { children } = this.props;
        const {
            products,
            btnThemes,
            fonts,
            product,
            productSelected,
            entryProduct,
            activeView,
            time,
            heatpumpAudio,
            disableSlider,
            heatpumpVolume,
            refernceHeatpumpVolume,
            viewLocation,
            viewLocationPrevious,
            selectedBackgroundAudio,
            backgroundAudio,
            backgroundVolume,
            appVolume,
            menuOpen,
            menuIsFloating,
            ambientAudio,
            productList,
            initialLoad,
            audioContext,
            gainNode,
            mediaSource,
            backgroundAudioLevels,
            heatpumpAudioLevels,
            userTracked,
            acceptedTerms,
        } = this.state;
        const {
            setProduct,
            setEntryProduct,
            setProducts,
            setHeatpumpAudio,
            setHeatpumpVolume,
            setActiveView,
            setTime,
            setAppVolume,
            setBackgroundVolume,
            setSelectedBackgroundAudio,
            setViewLocation,
            setViewLocationPrevious,
            setMenu,
            setMenuFloating,
            setAmbient,
            setInitialLoad,
            setAudioContext,
            setGainNode,
            setMediaSource,
            trackUser,
            setTerms,
            setSliderState,
        } = this;

        return (
            <AppContext.Provider
                value={{
                    products,
                    btnThemes,
                    fonts,
                    product,
                    entryProduct,
                    productSelected,
                    activeView,
                    time,
                    heatpumpAudio,
                    disableSlider,
                    viewLocation,
                    viewLocationPrevious,
                    selectedBackgroundAudio,
                    backgroundAudio,
                    backgroundVolume,
                    heatpumpVolume,
                    refernceHeatpumpVolume,
                    appVolume,
                    menuOpen,
                    menuIsFloating,
                    ambientAudio,
                    productList,
                    initialLoad,
                    audioContext,
                    gainNode,
                    mediaSource,
                    backgroundAudioLevels,
                    heatpumpAudioLevels,
                    userTracked,
                    acceptedTerms,
                    setProduct,
                    setEntryProduct,
                    setProducts,
                    setHeatpumpAudio,
                    setHeatpumpVolume,
                    setActiveView,
                    setTime,
                    setAppVolume,
                    setBackgroundVolume,
                    setSelectedBackgroundAudio,
                    setViewLocation,
                    setViewLocationPrevious,
                    setMenu,
                    setMenuFloating,
                    setAmbient,
                    setInitialLoad,
                    setAudioContext,
                    setGainNode,
                    setMediaSource,
                    trackUser,
                    setTerms,
                    setSliderState,
                }}
            >
                {children}
            </AppContext.Provider>
        );
    }
}

export default AppContext;

export { SimulatorProvider };

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import common_deDE from "./translations/deDE/common.json";
import common_deAT from "./translations/deAT/common.json";
import common_enUK from "./translations/enUK/common.json";
import common_nlNL from "./translations/nlNL/common.json";
import common_hrHR from "./translations/hrHR/common.json";
import common_bgBG from "./translations/bgBG/common.json";
import common_frFR from "./translations/frFR/common.json";
import common_itIT from "./translations/itIT/common.json";
import common_dkDK from "./translations/dkDK/common.json";
import common_esES from "./translations/esES/common.json";
import common_csCZ from "./translations/csCZ/common.json";
import common_roRO from "./translations/roRO/common.json";
import common_ptPT from "./translations/ptPT/common.json";
import common_nlBE from "./translations/nlBE/common.json";
import common_frBE from "./translations/frBE/common.json";

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend

    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: "deDE",
        debug: process.env.NODE_ENV !== "production",
        interpolation: { escapeValue: false },
        detection: {
            order: ["path"],
        },
        resources: {
            "de-DE": {
                translations: common_deDE,
            },
            deDE: {
                translations: common_deDE,
            },
            de: {
                translations: common_deDE,
            },
            "en-UK": {
                translations: common_enUK,
            },
            enUK: {
                translations: common_enUK,
            },
            en: {
                translations: common_enUK,
            },
            "it-IT": {
                translations: common_itIT,
            },
            itIT: {
                translations: common_itIT,
            },
            it: {
                translations: common_itIT,
            },
        },
        ns: ["translations"],
        defaultNS: "translations",
    });

export default i18n;

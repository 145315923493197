import React from "react";
import { Button, Form, FormGroup } from "reactstrap";
import { withRouter } from "react-router";
import AppContext from "../AppContext";
import Disclaimer from "./disclaimer/disclaimer";
import Liability from "./liability/liability";
import { withTranslation } from "react-i18next";
import Slider from "rc-slider";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import i18n from "i18next";
import styles from "../styles/home.module.css";
import "rc-slider/assets/index.css";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
var productEntry;
var entryParam;
var selectedTheme;
var btnColor;
var themeFont;
var labelFont;
var handIcon;

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            audio: "audiofile.mp3",
            initialVolumeSet: false,
            initialLoad: false,
            selectedProduct: 0,
            gainNode: {},
            mediaSource: {},
            audioCtx: {},
        };
    }

    static contextType = AppContext;

    componentWillMount() {
        const { products, btnThemes, fonts } = this.context;
        const productsProps = Object.getOwnPropertyNames(products);
        var foundTheme = 0;

        if (urlParams.get("theme")) {
            entryParam = urlParams.get("theme");
            //alert(entryParam)

            for (let themes = 0; themes < productsProps.length; themes++) {
                if (entryParam === productsProps[themes]) {
                    console.log(productsProps[themes]);

                    require("../styles/" + productsProps[themes] + ".css");

                    selectedTheme = productsProps[themes];
                    btnColor = btnThemes[entryParam][0];
                    themeFont = fonts[entryParam][0];
                    labelFont = fonts[entryParam][1];
                    console.log(selectedTheme);

                    handIcon = require(`../assets/img/hand-pointing-arrows-${selectedTheme}.svg`);

                    foundTheme++;
                } else {
                    require("ignore");
                    console.log("ignore:" + productsProps[themes]);
                }
            }

            if (foundTheme === 0) {
                require("../styles/" + productsProps[0] + ".css");
                selectedTheme = productsProps[0];
                btnColor = btnThemes.bosch[0];
                themeFont = fonts.bosch[0];
                labelFont = fonts.bosch[1];
                handIcon = require(`../assets/img/hand-pointing-arrows-${selectedTheme}.svg`);
            }
        } else {
            require("../styles/" + productsProps[0] + ".css");
            selectedTheme = productsProps[0];
            btnColor = btnThemes.bosch[0];
            themeFont = fonts.bosch[0];
            labelFont = fonts.bosch[1];
            handIcon = require(`../assets/img/hand-pointing-arrows-${selectedTheme}.svg`);
        }

        //tracking call
        this.trackingCall();
    }

    getEntryParameter = async () => {
        const { setProduct } = this.context;

        if (urlParams.get("entry")) {
            entryParam = urlParams.get("entry");

            var productList = await i18n.t("products", { returnObjects: true });

            var item = await productList.filter((item) => item.slug.toLowerCase().includes(entryParam));

            productEntry = await parseInt(item[0].menuId);

            setProduct(productEntry);
        } else {
            setProduct(0);
        }
    };

    trackingCall = () => {
        const { userTracked, trackUser } = this.context;

        if (!userTracked) {
            window.parent.postMessage({ event: "HP-Soundcomparisontool", eventCategory: "ToolStart", eventAction: window.location.href }, "*");
            trackUser(true);

            console.log(window.location.href);
        }
    };

    isInFrame = () => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("forceFrameMode")) {
            return true;
        }
        return window.self !== window.top;
    };

    handleChange = (e) => {
        const { setProduct } = this.context;

        setProduct(e.target.value);
    };

    handleClose = () => {
        this.setState({ snackbar: false });
    };

    setTermsState = (e) => {
        const { setTerms } = this.context;

        console.log(e);
        setTerms(e);
    };

    onSliderChange = (value) => {
        var activeToInt = parseInt(value);

        const { setActiveView } = this.context;

        setActiveView(activeToInt);

        this.changeProduct(value, "slider");
    };

    async playHeatpumpAudio(view, volume, active, origin, menuProduct) {
        const { heatpumpAudio, products, viewLocation, setHeatpumpVolume, setActiveView, setViewLocation, setViewLocationPrevious } = this.context;

        console.log(this.state.selectedProduct);
        setHeatpumpVolume(volume);

        if (!origin) {
            setViewLocationPrevious(viewLocation);
            setViewLocation(view);
            setActiveView(active);
        }

        var heatpump = document.getElementById("heatpumpAudioContainer");

        if (origin) {
            if (heatpump.duration > 0 && !heatpump.paused) {
                await heatpump.pause();
            }
        }

        heatpump.src = require(`../assets/audio/${products[selectedTheme][active].audio[0]}`);

        console.log(heatpump);
        heatpump.volume = volume;

        if (heatpumpAudio) {
            heatpump.play();
        }

        if (!this.state.initialLoad) {
            this.setState({ initialLoad: true });
        }
    }

    async changeProduct(e, origin) {
        if (origin) {
            var value = e.toString();
        } else {
            value = e.target.value;
        }

        switch (true) {
            case value === "0":
                await this.playHeatpumpAudio("outdoor", 1, 0, "home");
                break;
            case value === "1":
                await this.playHeatpumpAudio("outdoor", 1, 1, "home");
                break;
            case value === "2":
                await this.playHeatpumpAudio("indoor", 1, 2, "home");
                break;
            case value === "3":
                await this.playHeatpumpAudio("indoor", 1, 3, "home");
                break;
            case value === "4":
                await this.playHeatpumpAudio("indoor", 1, 4, "home");
                break;
            case value === "5":
                await this.playHeatpumpAudio("indoor", 1, 5, "home");
                break;
            case value === "6":
                await this.playHeatpumpAudio("indoor", 1, 6, "home");
                break;
            case value === "7":
                await this.playHeatpumpAudio("indoor", 1, 7, "home");
                break;
            case value === "8":
                await this.playHeatpumpAudio("indoor", 1, 8, "home");
                break;
            default:
        }
    }

    async toggleSound(e) {
        const { heatpumpAudio, setHeatpumpAudio, setSliderState } = this.context;

        var heatpump = document.getElementById("heatpumpAudioContainer");

        var transparentCover = document.getElementById("transparentCover");

        if (!heatpumpAudio) {
            heatpump.muted = false;
            setHeatpumpAudio(true);
            setSliderState(false);

            heatpump.play();

            transparentCover.style.display = "none";
        } else {
            heatpump.muted = true;
            setHeatpumpAudio(false);
            setSliderState(true);

            transparentCover.style.display = "block";
        }
    }

    render() {
        const { products, productSelected, heatpumpAudio, activeView, disableSlider } = this.context;

        const { t } = this.props;

        return (
            <div className={styles.homeContainer}>
                <div className={styles.setupContainer}>
                    <div>
                        <h3
                            className="headlineColor"
                            style={{ fontFamily: themeFont }}
                            dangerouslySetInnerHTML={{
                                __html: t("welcome." + [selectedTheme] + ".title", { interpolation: { escapeValue: false } }),
                            }}
                        ></h3>
                    </div>

                    <div className={`${styles.buttonAlign} ${styles.opacity1}`}>
                        <Button
                            onClick={() => {
                                this.toggleSound();
                            }}
                            className="soundToggleButton"
                            style={{ backgroundColor: btnColor }}
                        >
                            <div className={`${heatpumpAudio ? styles.hide : styles.showToggleBtn}`}>
                                <div style={{ paddingTop: "2px" }}>
                                    <FaVolumeUp style={{ display: "inline-block", width: "14px", height: "14px" }} />
                                </div>
                                <div style={{ paddingLeft: "4px", fontFamily: labelFont }}>{t("welcome.soundbutton.on")}</div>
                            </div>
                            <div className={`${!heatpumpAudio ? styles.hide : styles.showToggleBtn}`}>
                                <div style={{ paddingTop: "2px" }}>
                                    <FaVolumeMute style={{ display: "inline-block", width: "14px", height: "14px" }} />
                                </div>
                                <div style={{ paddingLeft: "4px", fontFamily: labelFont }}>{t("welcome.soundbutton.off")}</div>
                            </div>
                        </Button>
                    </div>

                    <div style={{ position: "relative", margin: "0 0 30px 0", textAlign: "left" }}>
                        <div
                            id="transparentCover"
                            style={{
                                position: "absolute",
                                width: "calc(100% + 40px)",
                                height: "100%",
                                top: "0px",
                                left: "-20px",
                                background: "#fff",
                                opacity: "0.8",
                                zIndex: "9999999999",
                            }}
                        ></div>

                        <Form>
                            <FormGroup
                                style={{ width: "calc(100% - 33px)", height: "20px", marginLeft: "0px", marginTop: "10px", zIndex: "999999999" }}
                            >
                                <Slider
                                    min={0}
                                    max={products[selectedTheme].length - 1}
                                    value={activeView}
                                    trackStyle={{ backgroundColor: "transparent", height: 10 }}
                                    railStyle={{
                                        backgroundColor: "transparent",
                                        width: "calc(100% + 35px)",
                                        height: 243,
                                        cursor: "pointer",
                                        zIndex: "999999999",
                                    }}
                                    handleStyle={{
                                        height: 55,
                                        width: 51,
                                        marginLeft: 18,
                                        marginRight: 8,
                                        marginTop: 190,
                                        backgroundImage: `url(${handIcon})`,
                                        backgroundSize: "cover",
                                        backgroundColor: "transparent",
                                        border: "none",
                                        borderRadius: "0px",
                                        zIndex: "999999999",
                                    }}
                                    sx={{
                                        "& .MuiSlider-disabled": {
                                            backgroundColor: "red",
                                        },
                                    }}
                                    onChange={this.onSliderChange}
                                    disabled={disableSlider}
                                />
                            </FormGroup>
                        </Form>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                fontFamily: labelFont,
                                fontSize: "12px",
                            }}
                        >
                            {products[selectedTheme].map((item) => (
                                <div
                                    key={item.id}
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignSelf: "end",
                                        width: "40px",
                                        height: item.bar.height,
                                        marginLeft: item.margin === "left" ? "0px" : "0px",
                                        marginRight: item.margin === "right" ? "-5px" : "0px",
                                    }}
                                >
                                    <div className={item.bar.hasPopover !== false ? styles.show : styles.hide}>
                                        <div
                                            className={activeView === item.id && item.bar.popover !== false ? styles.show : styles.hide}
                                            style={{
                                                position: "absolute",
                                                width: item.bar.popover.dimension,
                                                left: item.bar.popover.placement[0],
                                                top: item.bar.popover.placement[1],
                                                padding: item.bar.popover.padding,
                                                background: item.bar.popover.background,
                                                boxShadow: "0px 0px 4px 0px #000000",
                                                fontWeight: "800",
                                                fontSize: "11px",
                                                color: "#fff",
                                                zIndex: "999999999",
                                            }}
                                        >
                                            <a
                                                href={item.icon.link}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ color: "#FFF", textDecoration: "none" }}
                                            >
                                                {item.bar.popover.text}
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "4px",
                                            width: "40px",
                                            fontWeight: "800",
                                            fontSize: "11px",
                                            color: "#FFF",
                                            zIndex: "99999",
                                        }}
                                    >
                                        {item.bar.label.text}
                                    </div>
                                    <div
                                        style={{
                                            position: "absolute",
                                            left: "0px",
                                            width: "40px",
                                            height: item.bar.height,
                                            background: item.bar.background,
                                            zIndex: "99998",
                                        }}
                                    ></div>
                                </div>
                            ))}
                        </div>

                        <div>
                            <hr
                                style={{
                                    marginTop: "0",
                                    marginLeft: "-10px",
                                    marginBottom: "0",
                                    height: "0px",
                                    width: "calc(100% + 20px)",
                                    borderBottom: "1px solid #666666",
                                    border: "0",
                                    borderTop: "1px solid",
                                }}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                fontFamily: "Bosch-Regular",
                                fontSize: "12px",
                                textAlign: "center",
                                marginLeft: "0px",
                                marginTop: "0px",
                            }}
                        >
                            {products[selectedTheme].map((item) => (
                                <div key={item.id}>
                                    {!item.icon.link && (
                                        <img
                                            src={require(`../assets/img/${item.icon.filename}`)}
                                            alt=""
                                            style={{
                                                marginLeft: item.icon.placement[0],
                                                marginTop: item.icon.placement[1],
                                                width: item.icon.dimension,
                                            }}
                                        />
                                    )}

                                    {item.icon.link && (
                                        <a href={item.icon.link} target="_blank" rel="noreferrer">
                                            <img
                                                src={require(`../assets/img/${item.icon.filename}`)}
                                                alt=""
                                                style={{
                                                    marginLeft: item.icon.placement[0],
                                                    marginTop: item.icon.placement[1],
                                                    width: item.icon.dimension,
                                                }}
                                            />
                                        </a>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="footerContainer">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Liability theme={selectedTheme} />
                            <Disclaimer theme={selectedTheme} />
                        </div>
                        {!this.isInFrame() && (
                            <div>
                                <a
                                    className="imprintBtn btn btn-secondary"
                                    href={t("legal." + [selectedTheme] + ".imprint.url")}
                                    style={{ textDecoration: "none", fontFamily: labelFont }}
                                    target="_blank"
                                >
                                    {t("legal." + [selectedTheme] + ".imprint.label")}
                                </a>
                                <span>&nbsp;|&nbsp;</span>
                                <a
                                    className="imprintBtn btn btn-secondary"
                                    href={t("legal." + [selectedTheme] + ".dataprivacy.url")}
                                    style={{ textDecoration: "none", fontFamily: labelFont }}
                                    target="_blank"
                                >
                                    {t("legal." + [selectedTheme] + ".dataprivacy.label")}
                                </a>
                                <span>&nbsp;|&nbsp;</span>
                                <a
                                    className="imprintBtn btn btn-secondary"
                                    href={t("legal." + [selectedTheme] + ".termsconditions.url")}
                                    style={{ textDecoration: "none", fontFamily: labelFont }}
                                    target="_blank"
                                >
                                    {t("legal." + [selectedTheme] + ".termsconditions.label")}
                                </a>
                            </div>
                        )}
                    </div>
                </div>

                <audio
                    id="heatpumpAudioContainer"
                    className={styles.hide}
                    controls
                    loop
                    src={require(`../assets/audio/${products[selectedTheme][productSelected].audio[0]}`)}
                >
                    Your browser does not support the
                    <code>audio</code> element.
                </audio>
            </div>
        );
    }
}

export default withRouter(withTranslation()(Home));
